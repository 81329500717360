import schemes from './colorSchemes';

export const tokens = {
  button:{
    primary: {
      scheme: 'blue.darker'
    },
    secondary: {
      scheme: 'blue'
    },
    terciary: {
      scheme: 'gray',
      colors: {
        hover: 'gray.700'
      }
    },
    blueLight: {
      scheme: 'blue.light'
    },
  },
  // needs to reference the color string because sometimes is used outside a chakra component
  text: {
    primary: {
      color: schemes.medsimples.colors['blue.darker'][500]
    },
    primaryLight: {
      color: schemes.medsimples.colors['blue.light'][500]
    },
    primaryLighter: {
      color: schemes.medsimples.colors['blue.light'][100]
    },
    secondary: {
      color: schemes.medsimples.colors.blue[500]
    },
    highlight: {
      color:  schemes.medsimples.colors.cyan[500]
    },
    gray: {
      color: schemes.medsimples.colors.gray[500]
    },
    inputGray: {
      color: schemes.medsimples.colors.gray[800]
    },
    borderGray: {
      color: schemes.medsimples.colors.gray[200]
    },
    grayLight: {
      color: schemes.medsimples.colors.gray[100]
    },
    grayLighter: {
      color: schemes.medsimples.colors.gray[50]
    },
    yellow: {
      color: schemes.medsimples.colors.yellow[300]
    },
    green: {
      color: schemes.medsimples.colors.green[600]
    },
    orange: {
      color: schemes.medsimples.colors.orange[400]
    },
    red: {
      color: schemes.medsimples.colors.red[500]
    },
    white: {
      color: '#FFFFFF'
    }
  },
  background: {
    primary: {
      color: 'blue.light.500'
    },
    gray: {
      color: 'rgba(251, 251, 251, 0.50)'
    },
    grayHighlight: {
      color: 'rgba(241, 241, 241, 0.50)'
    },
    ice: {
      color: '#F2F6FF'
    }
  }
};
